<div class="pchart">
	<line-chart :height="typeof options.height !== 'undefined' ? options.height : undefined" :width="typeof options.width !== 'undefined' ? options.width : undefined"
	 :chart-data="cData"></line-chart>
</div>

<script>
	import {
		Line,
		mixins
	} from 'vue-chartjs';

	export default {
		name: 'LineChart',
		extends: Line,
		mixins: [mixins.reactiveProp],
		components: {
			'line-chart': Line
		},
		data() {
			return {
				datacollection: null
			};
		},
		methods: {
			renderPChart() {
				let options = {};
				if (this.options == null) {
					options['responsive'] = true;
					options['maintainAspectRatio'] = false;
				} else {
					options = { ...{
							responsive: true,
							maintainAspectRatio: false
						},
						...this.options
					};
				}

				this.renderChart(this.cData, options);
			}
		},
		computed: {
			cData() {
				return this.chartData;
			}
		},
		mounted() {
			this.renderPChart();
		},
		watch: {
			cData() {
				this.$data._chart.destroy();
				this.renderPChart();
			}
		},
		beforeDestroy() {
			this.$data._chart.destroy();
		},
		props: {
			chartData: {
				type: Object,
				required: true
			},
			options: {
				type: Object,
				required: false
			}
		}
	};
</script>